@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face { font-family: 'Nunito'; src: url('../public/Nunito.ttf') format('woff2'); }
body,html { font-family: "Nunito", sans-serif; }

.spinner-loader {
    border-radius: 50%;
    animation: spin 0.5s linear infinite;
    margin: auto;
}
.spinner-normal {
    width: 120px;
    height: 120px;
    border: 4px solid #f3f3f3; 
    border-top: 16px solid #4200ab;
}
.spinner-small {
    width: 16px;
    height: 16px;
    border: 1px solid #f3f3f3; 
    border-top: 2px solid #4200ab;
}
@keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); }}